import { render, staticRenderFns } from "./Canales.vue?vue&type=template&id=35fa4111"
import script from "./Canales.vue?vue&type=script&lang=js"
export * from "./Canales.vue?vue&type=script&lang=js"
import style0 from "./Canales.vue?vue&type=style&index=0&id=35fa4111&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports