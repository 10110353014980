<template>
    <div class="app flex-row align-items-center">
        <div class="row" style="padding: 0px !important; margin-top: -3px;margin-left: 0px;margin-right: 0px;margin-bottom: 0px;width: 100%">

            <div class="col-md-12" style="padding-left: 50px;padding-right: 50px ">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <h4 class="demo-title"><strong>Canales</strong></h4>
                </mdb-row>

                <hr />

                <mdb-row v-for="canal in this.canales" style="width: 100%">


                    <mdb-card v-bind:style="estilo_row">

                        <mdb-row  style="width: 100%;min-height: 200px;">

                                <img  @click="IraCanal($event,canal)" v-if="esta_en_vertical == false" style="width:16%;max-height: 200px" v-bind:src="'https://api.agustinos.tv/api/imagenes/delegacion/' + canal.delegacion_id +'/' + token+'?w=200'">
                            <img  @click="IraCanal($event,canal)" v-if="esta_en_vertical == true" style="width:100%;margin-left: 15px" v-bind:src="'https://api.agustinos.tv/api/imagenes/delegacion/' + canal.delegacion_id + '/' + token+'?w=200'">

                                <mdb-card-body>
                                    <div style="width: 100%;height: 100%" @click="IraCanal($event,canal)">
                                    <mdb-card-title>{{canal.nombre}}</mdb-card-title>
                                    <mdb-card-text>{{canal.delegacion.nombre}}</mdb-card-text>
                                    </div>
                                </mdb-card-body>
                        </mdb-row>

                    </mdb-card>
                </mdb-row>


            </div>

        </div>
    </div>


</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbMediaImage,
        mdbBadge,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';
    import 'video.js/dist/video-js.css'
    import { videoPlayer } from 'vue-video-player';

    export default {
        name: 'Home',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbBtn,
            mdbBadge,
            mdbCardGroup,
            videoPlayer,
            mdbMediaImage,
        },
        data() {
            return {
                canales: [],
                estilo_row : 'width: 100%;margin: 10px 30px;',
                esta_en_vertical: false,
                token : localStorage.getItem('token'),

            }
        },

        methods: {

            IraCanal(e,dato) {

                this.$router.push({path: 'canal/' + dato.id});
            }
        },
        created(){


            if(window.innerHeight > window.innerWidth)
            {
                this.esta_en_vertical = true;
                document.querySelector("html").style.fontSize = '.80rem';
                this.estilo_row = 'width: 100%;padding-right:5px;padding-left:5px;padding-top:10px;padding-bottom:10px;';
            }

            ApiService.getCanales().then(response => {
                console.log('video');
                console.log(response);
                this.canales = response.data.data;


                ApiService.isMobile().then(response => {
                    console.log('Ejecutando IsMobile ...');
                    console.log(response);
                    this.is_mobile = response.data.is_mobile.toString();
                    if(localStorage.getItem('is_mobile'))
                    {

                        this.is_mobile = localStorage.getItem('is_mobile');
                        console.log('leyendo is_mobile : ' + this.is_mobile );

                    }

                    else {
                        localStorage.setItem('is_mobile', this.is_mobile);
                        this.$forceUpdate();
                    }




                });
            });





            /*if(this.is_mobile == 'false') {
                console.log('ejecuntado seccion mobile false');
                if (localStorage.getItem('tam_video')) {
                    console.log('tam video : ');

                    this.ancho_video = localStorage.getItem('tam_video');
                    console.log(this.ancho_video);
                } else {
                    //Generar TAMVideo y recargar
                    this.screenHeight = window.innerHeight;
                    this.screenWidth = window.innerWidth;
                    console.log('Screen height' + this.screenHeight);
                    console.log('Screen Width' + this.screenWidth);

                    this.ancho_barra = window.innerWidth - this.ancho_video - 15;
                    localStorage.setItem('tam_video', this.ancho_video);
                    this.$forceUpdate();

                }


                this.screenHeight = window.innerHeight;
                this.screenWidth = window.innerWidth;
                console.log('Screen height' + this.screenHeight);
                console.log('Screen Width' + this.screenWidth);

                this.ancho_barra = window.innerWidth - this.ancho_video - 15;
            }*/






        },
        mounted()
        {

        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }

</style>